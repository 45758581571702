document.addEventListener('DOMContentLoaded', function() {
    iniciarApp();
});

function iniciarApp() {
    navegacionFija();
    crearGaleria();
    scrollNav();
    mostrarPena();
    mostrarMapa();
}

function navegacionFija() {
    const barra = document.querySelector('.header');
    const sobreKelvin = document.querySelector('.sobre-kelvin');
    const body = document.querySelector('body');

    window.addEventListener('scroll', function() {
        // console.log(sobreFestival.getBoundingClientRect());

        if(sobreKelvin.getBoundingClientRect().top <0 ) {
            barra.classList.add('fijo');
            body.classList.add('body-scroll');
        } else {
            barra.classList.remove('fijo');
            body.classList.remove('body-scroll');
        }
    });
}
function scrollNav() {
    const enlaces = document.querySelectorAll('.navegacion-principal a');

    enlaces.forEach( enlace => {
        enlace.addEventListener('click', function(e) {
            e.preventDefault();

            const seccionScroll = e.target.attributes.href.value;
            const seccion = document.querySelector(seccionScroll);
            seccion.scrollIntoView({behavior: "smooth"});
            
        });
    })
}
function mostrarPena() {
    const servicios = document.querySelectorAll('.nombre-servicio');

    servicios.forEach( servicios => {
        servicios.addEventListener('click', function(e) {
            // e.preventDefault();
            const tranca = e.target.getAttribute("id");
            mostrarDibujo(tranca);
            // console.log(e.target);
        });
        // console.log(tranca);
        })
       
}
// function mostrarTexto() {
//     const texto = document.querySelectorAll('.descripcion');

//     texto.forEach( texto => {
//         texto.addEventListener('click', function(e) {
//             // e.preventDefault();
//             const textoTranca = e.target.getAttribute("class");
//             // mostrarDibujo(textoTranca);
//             console.log(textoTranca);
//         });
//         // console.log(tranca);
//         })
       
// }

// function mostrarDibujo(tranca) {
//     console.log('mosrtando...', tranca)
// }

function mostrarDibujo(id) {

    const dibujo = document.createElement('picture');
    // const pTexto = document.createElement('p');
    dibujo.innerHTML = `
        <source srcset="build/img/${id}-texto.avif" type="image/avif">
        <source srcset="build/img/${id}-texto.webp" type="image/webp">
        <img loading="lazy" width="200" height="300" src="build/img/${id}-texto.png" alt="Imagen galeria"></img>
        `;
    // pTexto.innerHTML = `La Puta que te Parió ${id}`;
    
    // Crea el overlay con la imagen
    const overlay = document.createElement('DIV');
    overlay.appendChild(dibujo);
    // overlay.appendChild(pTexto);
    overlay.classList.add('overlay');
    overlay.onclick = function() {
        const body = document.querySelector('body');
        body.classList.remove('fijar-body');
        overlay.remove();
    }

       // Añadirlo al HTML
    const body = document.querySelector('body');
    body.appendChild(overlay);
    body.classList.add('fijar-body');

    // Mostrar descripcion del servicio
    // const descripcion = document.querySelector('.descripcion');
    // descripcion.classList.add('overlay');
    // descripcion.onclick = function() {
    //     const desc = document.querySelector('.descripcion');
    //     desc.classList.remove('overlay');
    //     overlay.remove();
    //     console.log(descripcion);
    // } 
}   
// function mostrarDibujo() {
//     console.log('mostrando...');
// }
function crearGaleria() {
    const galeria = document.querySelector('.clientes-imagenes');

    for(let i = 1; i <= 4; i++)  {
        const imagen = document.createElement('picture');
        imagen.innerHTML = `
            <source srcset="build/img/thumb/${i}.avif" type="image/avif">
            <source srcset="build/img/thumb/${i}.webp" type="image/webp">
            <img loading="lazy" width="200" height="300" src="build/img/thumb/${i}.jpg" alt="Imagen galeria"></img>
            `;
            // imagen.onclick = function() {
            //     mostrarImagen(i);
            // }
        galeria.appendChild(imagen);
    }
}
// var map = L.map('map').
// setView([-32.8817, -60.705085],
// 15);
// L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
// attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://cloudmade.com">CloudMade</a>',
// maxZoom: 18
// }).addTo(map);
// L.marker([-32.8817, -60.705085],{draggable: true}).addTo(map);
function mostrarMapa() {
var map = L.map('map').setView([-32.8817, -60.705085], 14);
var myIcon = L.icon({
    iconUrl: 'build/img/my-icon.png',
    iconSize: [20, 0],
    iconAnchor: [22, 94],
    popupAnchor: [0, 0],
    // shadowUrl: 'build/img/my-icon.png',
    // shadowSize: [50, 150],
    // shadowAnchor: [12, 54]
});

L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
}).addTo(map);
L.control.scale().addTo(map);

L.marker([-32.8817, -60.705085], {icon: myIcon}).addTo(map)
    .bindPopup('Ambiente y Energía.<br> Laboratorio Ambiental')
    .openPopup();
}
// function mostrarImagen(id) {
//     const imagen = document.createElement('picture');
//     imagen.innerHTML = `
//         <source srcset="build/img/grande/${id}.avif" type="image/avif">
//         <source srcset="build/img/grande/${id}.webp" type="image/webp">
//         <img loading="lazy" width="200" height="300" src="build/img/grande/${id}.jpg" alt="Imagen galeria"></img>
//         `;
    
//     // Crea el overlay con la imagen
//     const overlay = document.createElement('DIV');
//     overlay.appendChild(imagen);
//     overlay.classList.add('overlay');
//     overlay.onclick = function() {
//         const body = document.querySelector('body');
//         body.classList.remove('fijar-body');
//         overlay.remove();
//     }

//     // Boton para cerrar el modal
//     // const cerrarModal = document.createElement('P');
//     // cerrarModal.textContent = 'X';
//     // cerrarModal.classList.add('btn-cerrar');
//     // cerrarModal.onclick = function() {
//     //     const body = document.querySelector('body');
//     //     body.classList.remove('fijar-body');
//     //     overlay.remove();
//     // };
//     // overlay.appendChild(cerrarModal);

//     // Añadirlo al HTML
//     const body = document.querySelector('body');
//     body.appendChild(overlay);
//     body.classList.add('fijar-body');
// }
